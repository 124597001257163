import saveSurveyData from "@app/controllers/bare_forms/form_functions/saveSurveyData";
import getEulogy from "@app/controllers/bare_forms/form_functions/getEulogy";

export default async (survey) => {
  const surveyContainer = document.getElementById("surveyContainer");
  const completeRedirect = surveyContainer.getAttribute("data-complete-url");
  const dashboardStage = JSON.parse(surveyContainer.getAttribute("data-default-response"))[
    "dashboard_stage"
  ];

  const pageBody = document.querySelector(".sd-body");
  pageBody.classList.add("h-fit");

  if (dashboardStage == "deal") {
    createButtonForDeal();
  } else {
    createButtonForTicket();
  }

  saveSurveyData(survey, true, false).then(() => {
    if (!window.location.href.includes("preconsultation_form")) {
      redirectTo(survey, "form/success");
    }
  });

  if (completeRedirect && document.querySelector(".eulogies")) {
    getEulogy();
  }
};

function createButtonForDeal() {
  const url = window.location.href.replace("form", "home");
  const buttonText = "Return to dashboard";

  const button = createButton(url, buttonText);
  appendToPage(button);
}

function createButtonForTicket() {
  if (window.location.href.includes("preconsultation_form")) {
    const url = window.location.href.replace("preconsultation_form", "home");
    const buttonText = "Go to Home";

    const button = createButton(url, buttonText);
    appendToPage(button);
  }
}

function createButton(url, text) {
  let button = document.createElement("a");
  button.className = "button--primary home-button";
  button.innerText = text;
  button.href = url;
  return button;
}

function appendToPage(button) {
  const pageBody = document.querySelector(".sd-body");
  if (pageBody) {
    pageBody.appendChild(button);
  }
}

async function redirectTo(survey, page) {
  try {
    window.location.href = window.location.href.replace("form", page);
  } catch {
    console.error("Failed to save survey data before redirecting.");
  }
}
